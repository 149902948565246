import { FC, useState } from "react";

import { PortalMessageService } from "@spwn-portal/grpc";
import { RiNotification4Line } from "@spwn-portal/icon/remix-icon";
import { Box, FormLabel, Icon, Stack, Switch } from "@spwn-portal/ui/chakra";

import { useConnectClient } from "../../lib/connect";

export const AccountMenuDMNotificationSetting: FC<{
  isChecked: boolean;
}> = ({ isChecked }) => {
  const client = useConnectClient(PortalMessageService);

  const [receiveNotification, setReceiveNotification] =
    useState<boolean>(isChecked);

  return (
    <Box
      paddingX={4}
      paddingY={3}
      aria-label="新着メッセージの通知をメールで受け取るか設定"
    >
      <Stack direction={"row"} alignItems={"center"}>
        <Icon w={6} height={6} as={RiNotification4Line} />
        <FormLabel
          fontSize={"sm"}
          lineHeight={5}
          fontWeight={"normal"}
          htmlFor="email-notification-setting"
          mb="0"
        >
          新着メッセージの通知をメールで受け取る
        </FormLabel>
        <Switch
          isChecked={receiveNotification}
          onChange={async (e) => {
            setReceiveNotification(e.target.checked);
            await client.updateUserDmNotificationSetting({
              receiveEmail: e.target.checked,
            });
          }}
          colorScheme="green"
          id="email-notification-setting"
        />
      </Stack>
    </Box>
  );
};
