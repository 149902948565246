import { PortalMessageService } from "@spwn-portal/grpc";

import { useSWRSuspense } from "../../contexts/swr";
import { useConnectClient } from "../../lib/connect";

export const useGetDmNotificationSettings = (brandId: string) => {
  const client = useConnectClient(PortalMessageService);
  return useSWRSuspense(
    {
      key: "getDmNotificationSettings",
      brandId: brandId,
    },
    async () => {
      try {
        const { receiveEmail } = await client.getUserDmNotificationSetting({});
        return {
          isChecked: receiveEmail,
        };
      } catch (e) {
        return { isChecked: false };
      }
    }
  );
};
