// const JINTOMO_TENANT_ID_PROD = "df9NaSiy8DnBKuxRPs7Q";
// const SAMPLE_TENANT_A_ID_DEV = "QR62WSz8I6innJqvLShV";
const BALUS_TENANT_ID_DEV = "te_8wSQPZRVs9Y1Cp1sRVF0";

export const isEnableSelfShippingGoods = (tenantId: string): boolean => {
  if (tenantId === BALUS_TENANT_ID_DEV) {
    return true;
  }

  return process.env.NEXT_PUBLIC_ENABLE_SELF_SHIPPING_GOODS === "true";
};

export const isEnableFanclubEvent = (tenantId: string): boolean => {
  if (tenantId === BALUS_TENANT_ID_DEV) {
    return true;
  }

  return process.env.NEXT_PUBLIC_ENABLE_FANCLUB_EVENT_FEATURE === "true";
};
