import NextLink from "next/link";
import React, { FC, useCallback } from "react";

import { IoMailOutline } from "@spwn-portal/icon/ionicons5";
import {
  Box,
  Stack,
  Text,
  useClipboard,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AvatarBadge,
  Icon,
  Flex,
} from "@spwn-portal/ui/chakra";

import { AccountMenuDMNotificationSetting } from "./coreFan/AccountMenuDMNotificationButton";
import { useGetDmNotificationSettings } from "./coreFan/useGetDmNotificationSetting";
import { useGetUnreadMessageExistsStatus } from "./message/useGetUnreadMessageExistsStatus";
import { Navigate } from "./Navigate";
import { pagesPath } from "./path";
import { useLogout } from "./useLogout";
import { SPWN_ACCOUNT_EDIT_URL, SPWN_URL } from "../constants/spwn";
import { User } from "../contexts/auth";
import { useBrandContext } from "../contexts/brand";
import { AddressListIcon } from "../ui/AddressListIcon";
import { AvatarIcon } from "../ui/AvatarIcon";
import { ClipboardIcon } from "../ui/ClipboardIcon";
import { ExternalLinkIcon } from "../ui/ExternalLinkIcon";
import { LogoutIcon } from "../ui/LogoutIcon";
import { MyPlanIcon } from "../ui/MyPlanIcon";
import { PaymentHistoryIcon } from "../ui/PaymentHistoryIcon";
import { ProfileIcon } from "../ui/ProfileIcon";
import { SpwnPortalLogo } from "../ui/SpwnPortalLogo";

type AccountMenuProps = {
  isEnableSelfShippingGoods: boolean;
  user: User;
};

/**
 * アカウントメニュー
 *
 * TODO UI実装 https://www.figma.com/file/SP0QKTfAvX3OdFHYQi5jrC/FanClub-Portal?node-id=862%3A52143&t=GFss9puVg42qIBmD-4
 */
export const AccountMenu: FC<AccountMenuProps> = ({
  user,
  isEnableSelfShippingGoods,
}) => {
  const toast = useToast();

  const { onCopy: onCopyUserUid } = useClipboard(user.uid);

  const handleSPWNIdClick = useCallback(() => {
    onCopyUserUid();

    toast({
      title: "コピーしました",
      status: "success",
      position: "top",
    });
  }, [onCopyUserUid, toast]);

  const logout = useLogout();

  const unreadMessageExists = useGetUnreadMessageExistsStatus();

  const { brandId } = useBrandContext();
  const {
    data: { isChecked: isGetDMNotificationChecked },
  } = useGetDmNotificationSettings(brandId);

  return (
    /**
     * FIXME Box がないとCSS warningが発生する https://github.com/chakra-ui/chakra-ui/issues/3440
     * workaround: https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911
     */
    <Box>
      <Menu placement="bottom-end">
        {/* メニューのトリガーボタン */}
        <MenuButton>
          {/* NOTE Header を表示したとき、PC 表示だと時間差で縦幅が伸びる */}
          {/* 原因はこの AvatarIcon のサイズにあることまではわかっている (size={"xs} にすると解消する) */}
          <AvatarIcon src={user.icon} size={["xs", "sm"]}>
            {unreadMessageExists.data?.ok &&
              unreadMessageExists.data?.result.exists && (
                <AvatarBadge
                  boxSize="0.8rem"
                  bg="icon.critical"
                  placement="top-end"
                  border={0}
                />
              )}
          </AvatarIcon>
        </MenuButton>

        {/* メニューリスト */}
        <MenuList width={"300px"}>
          {/* ユーザープロフィール */}
          <Box paddingX={4} paddingY={3}>
            <Stack direction={"row"} spacing={3} alignItems={"center"}>
              <AvatarIcon src={user.icon} />
              <Stack direction={"column"} spacing={1}>
                <Text fontSize={"md"} fontWeight={"bold"}>
                  {user.displayName}
                </Text>
                <Text fontSize={"xs"} wordBreak={"break-all"}>
                  {user.email}
                </Text>
              </Stack>
            </Stack>
          </Box>

          {/* SPWN ID */}
          <MenuItem
            paddingX={4}
            paddingY={3}
            /**
             * TODO MenuDivider で実装してもいいかも。他の MenuItem も同様。
             *  https://github.com/balus-co-ltd/spwn-portal/pull/153#discussion_r1083609949
             */
            borderY={"1px solid"}
            borderColor={"border"}
            closeOnSelect={false}
            onClick={handleSPWNIdClick}
            aria-label="SPWN IDをコピー"
          >
            <Stack direction={"column"} spacing={1}>
              <Text fontSize={"xs"} fontWeight={"bold"}>
                SPWN ID
              </Text>
              <Stack direction={"row"} alignItems={"center"}>
                <Text fontSize={"xs"} wordBreak={"break-all"}>
                  {user.uid}
                </Text>
                <ClipboardIcon />
              </Stack>
            </Stack>
          </MenuItem>

          {/* 各ユーザーページ */}
          {/* TODO コンテンツ一覧ページが実装された後に表示し、リンクを書き換える */}
          {/*<NextLink href={pagesPath.$url()} passHref>*/}
          {/*  <MenuItem as={"a"} paddingX={4} paddingTop={3} paddingBottom={2}>*/}
          {/*    <Stack direction={"row"} alignItems={"center"}>*/}
          {/*      <ContentIcon width={6} height={6} />*/}
          {/*      <Text fontSize={"sm"}>購入中のコンテンツ</Text>*/}
          {/*    </Stack>*/}
          {/*  </MenuItem>*/}
          {/*</NextLink>*/}
          <MenuItem
            as={"a"}
            href={SPWN_ACCOUNT_EDIT_URL}
            target={"_blank"}
            rel={"noopener noreferrer"}
            paddingX={4}
            paddingY={2}
            aria-label={"プロフィールを見る"}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <ProfileIcon width={6} height={6} />
              <Text fontSize={"sm"}>プロフィール</Text>
            </Stack>
          </MenuItem>

          <Navigate
            href={pagesPath.direct_message.$url({
              query: {},
            })}
          >
            <MenuItem
              as={"a"}
              target={"_blank"}
              rel={"noopener noreferrer"}
              paddingX={4}
              paddingY={2}
              aria-label={"メッセージを見る"}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Flex position={"relative"} alignItems={"center"}>
                  <Icon as={IoMailOutline} w={6} h={6} />
                  {unreadMessageExists.data?.ok &&
                    unreadMessageExists.data?.result.exists && (
                      <Box position={"absolute"} top={-2.0} right={-1.5}>
                        <Icon color={"icon.critical"}>
                          <circle cx="10" cy="10" r="10" fill="currentColor" />
                        </Icon>
                      </Box>
                    )}
                </Flex>

                <Text fontSize={"sm"}>メッセージ</Text>
              </Stack>
            </MenuItem>
          </Navigate>

          <NextLink href={pagesPath.account.plans.$url()} passHref>
            <MenuItem
              as={"a"}
              paddingX={4}
              paddingY={2}
              aria-label={"マイプラン一覧を見る"}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <MyPlanIcon width={6} height={6} />
                <Text fontSize={"sm"}>マイプラン</Text>
              </Stack>
            </MenuItem>
          </NextLink>
          <NextLink href={pagesPath.account.order_history.$url()} passHref>
            <MenuItem
              as={"a"}
              paddingX={4}
              paddingTop={2}
              paddingBottom={3}
              aria-label="購入履歴を見る"
            >
              <Stack direction={"row"} alignItems={"center"}>
                <PaymentHistoryIcon width={6} height={6} />
                <Text fontSize={"sm"}>購入履歴</Text>
              </Stack>
            </MenuItem>
          </NextLink>

          {isEnableSelfShippingGoods && (
            <NextLink href={pagesPath.account.addresses.$url()} passHref>
              <MenuItem
                as={"a"}
                paddingX={4}
                paddingTop={2}
                paddingBottom={3}
                aria-label="配送先情報"
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <AddressListIcon width={6} height={6} />
                  <Text fontSize={"sm"}>配送先情報</Text>
                </Stack>
              </MenuItem>
            </NextLink>
          )}

          {/* SPWN サイト */}
          <MenuItem
            as={"a"}
            href={SPWN_URL}
            target={"_blank"}
            rel={"noopener noreferrer"}
            paddingX={4}
            paddingY={3}
            borderY={"1px solid"}
            borderColor={"border"}
            aria-label={"SPWN Portalへ"}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <SpwnPortalLogo variant={"oneLine"} size={"sm"} />
              <ExternalLinkIcon width={4} height={4} />
            </Stack>
          </MenuItem>
          <AccountMenuDMNotificationSetting
            isChecked={isGetDMNotificationChecked}
          />

          {/* ログアウト */}
          <MenuItem
            paddingX={4}
            paddingY={3}
            onClick={logout}
            aria-label="ログアウトする"
          >
            <Stack direction={"row"} alignItems={"center"}>
              <LogoutIcon width={6} height={6} />
              <Text fontSize={"sm"}>ログアウト</Text>
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
