import useSWR from "swr";

import {
  PortalMessageService,
  PortalMessageServiceGetUnreadMessageExistsStatusRequest,
} from "@spwn-portal/grpc";

import { useConnectClient } from "../../lib/connect";

export const useGetUnreadMessageExistsStatus = () => {
  const client = useConnectClient(PortalMessageService);

  return useSWR<
    | {
        ok: true;
        result: {
          exists: boolean;
        };
      }
    | {
        ok: false;
      }
  >(
    {
      key: "getUnreadMessageExistsStatus",
    },
    async () => {
      try {
        const { numberOfUnreadChatPartners } =
          await client.getUnreadMessageExistsStatus(
            new PortalMessageServiceGetUnreadMessageExistsStatusRequest()
          );

        return {
          ok: true,
          result: {
            exists: numberOfUnreadChatPartners > 0,
          },
        };
      } catch (e) {
        return {
          ok: false,
        };
      }
    },
    {
      suspense: false,
      errorRetryCount: 1,
    }
  );
};
